"use client";
import {
  BaseButton,
  ButtonProps,
  GhostButton,
  PrimaryButton,
  SecondaryButton,
} from "@cakemembers/components-core";
import { copy } from "@cakemembers/utils";

type StringOrFunc = string | (() => string);

export function CopyButton<T extends React.ElementType = typeof BaseButton>({
  text,
  children,
  as,
  onClick,
  ...props
}: ButtonProps & { as?: T | "secondary" | "ghost"; text: StringOrFunc }) {
  let ButtonComponent = PrimaryButton;
  if (as === "secondary") {
    ButtonComponent = SecondaryButton;
  } else if (as === "ghost") {
    ButtonComponent = GhostButton;
  }

  function handleClick(e: any) {
    if (typeof text === "string") {
      console.log("copy", text);
      copy(text);
    } else {
      copy(text());
    }
    onClick && onClick(e);
  }
  return (
    <ButtonComponent onClick={handleClick} {...props}>
      {children}
    </ButtonComponent>
  );
}
